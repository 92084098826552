<template>
    <vx-card :title="'Item Suggestion Stock Transfer Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th>STO Data</th>
                            <th>Picking List Data</th>
                            <th>Destination Warehouse</th>
                            <th>Destination Address</th>
                            <th>Delivery Date</th>
                            <th>ETA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="code" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="pickingCode" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="selectedDestWarehouse" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="destAddress" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="formatDate(this.reqDeliveryDate)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="formatDate(this.eta)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <hr>
        <br>
        <vs-table stripe style="width:100%" :data="pickingItem">
            <thead>
                <tr>
                    <th width="12%" style="vertical-align: middle;" rowspan="2">SKU Code</th>
                    <th width="20%" style="vertical-align: middle;" rowspan="2">SKU Name</th>
                    <th width="10%" style="vertical-align: middle;" rowspan="2">HU</th>
                    <th width="10%" style="vertical-align: middle;" rowspan="2">Quantity</th>
                    <th style="text-align: center;" colspan="3">Suggestion</th>
                </tr>
                <tr>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                </tr>
            </thead>
            <template>
                <template v-for="(t, indext) in pickingItem">
                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.StockRelocationPickingSuggestions">
                        <vs-td>{{ tr.SkuCode }}</vs-td>
                        <vs-td>{{ tr.ItemName }}</vs-td>
                        <vs-td>{{ tr.Unit }}</vs-td>
                        <vs-td>{{ tr.Amount }}</vs-td>
                        <vs-td class="{'pb-32': selects[`select-${indext}-${indextr}`]}">
                            <vs-select v-model="tr.StorageCode">
                                <vs-select-item 
                                    v-for="(item,index) in optionStorage(tr.ItemUnitID)"
                                    :key="index"
                                    :value="item"
                                    :text="item">
                                </vs-select-item>
                            </vs-select>
                        </vs-td>
                        <vs-td>
                            <vs-select v-model="tr.Batch">
                                <vs-select-item 
                                    v-for="(item,index) in optionBatch(tr.StorageCode, tr.ItemUnitID)"
                                    :key="index"
                                    :value="item"
                                    :text="item">
                                </vs-select-item>
                            </vs-select>
                        </vs-td>
                        <vs-td>
                            <vs-select v-model="tr.ExpiredDate" style="width: 100px;">
                                <vs-select-item 
                                    v-for="(item,index) in optionExpired(tr.Batch, tr.ItemUnitID)"
                                    :key="index"
                                    :value="item"
                                    :text="formatDate(item)"></vs-select-item>
                            </vs-select>
                        </vs-td>
                    </vs-tr>
                </template>
            </template>
        </vs-table>
        <br>
        <div class="mb-6 space-x-1 flex" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="success" v-on:click="handleSave">Save</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export default {
    components: {
        flatPickr,
    },
    data() {
        return {
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                // minTime: "00:00",
                plugins: [new ConfirmDatePlugin()],
            },
            pickingDate: this.date,
            id: null,
            readonly: true,
            code: null,
            status: null,
            pickingId: 0,
            pickingCode: null,
            warehouseId: 0,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            destAddress: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            file: null,
            fileUrl: '',
            pickingItem: [],
            stock: [],
        }
    },
    mounted() {
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()

        this.id = this.$route.params.id;
        if (this.id) {
            this.loadPage()
        }
    },
    methods: {
        async loadPage() {
            await this.getData()
            await this.getSuggest()
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get("/api/wms/v1/outbound/sto-planner/" + this.id);
            if (resp.code == 200) {
                this.code = resp.data.code
                this.status = resp.data.status
                this.pickingId = resp.data.picking_id
                this.pickingCode = resp.data.picking_code
                this.warehouseId = resp.data.selected_src_warehouse.id
                this.selectedSrcWarehouse = resp.data.selected_src_warehouse.label
                this.selectedDestWarehouse = resp.data.selected_dest_warehouse.label
                this.destAddress = resp.data.selected_dest_warehouse.name
                this.reqDeliveryDate = moment(resp.data.req_delivery_date).toDate()
                this.eta = moment(resp.data.eta).toDate()
                this.notes = resp.data.notes
                this.fileUrl = resp.data.file_url
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
        async getSuggest() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-planner/get-suggestion", {
                    params: {
                        picking_id: this.pickingId,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.pickingItem = resp.data.StockRelocationPickingItems
                    }
                })
                .then(() => {
                    const itemUnitIds = this.pickingItem.reduce((sum, p) => [...sum, ...p.StockRelocationPickingSuggestions.map((ps) => ps.ItemUnitID)], [])
                    return this.$http
                        .get("/api/wms/v1/outbound/sto-planner/get-stock", {
                            params: {
                                item_unit_ids: itemUnitIds,
                                warehouse_id: this.warehouseId,
                            }
                        })
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.code == 200) {
                            this.stock = resp.data
                            this.$vs.loading.close();
                        } else {
                            this.$vs.loading.close();
                        }
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleSave(){
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to update this suggestions?",
                accept: () => {
                    this.$vs.loading()
                    const suggestion = this.pickingItem.reduce((sum, p) => [...sum, ...p.StockRelocationPickingSuggestions.map((ps) => ({id: ps.id, storage_code: ps.StorageCode, batch: ps.Batch, expired_date: this.formatDate(ps.ExpiredDate)}))], [])
                    this.$http
                        .post("/api/wms/v1/outbound/sto-planner/save-suggestion", {suggestion})
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.handleBack()
                                this.$vs.loading.close();
                            } else {
                                this.$vs.loading.close();
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            })
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-planner",
                params: {status: 1}
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        optionStorage: function () {
            return (itemUnitId) => [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId).map((p) => p.StorageCode))]
        },
        optionBatch: function () {
            return (storage, itemUnitId) => [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.StorageCode == storage).map((p) => p.Batch))]
        },
        optionExpired: function () {
            return (batch, itemUnitId) => [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.Batch == batch).map((p) => p.ExpiredDate))]
        },
    },
}
</script>